import consumer from "./consumer"

// need to wrap in a document.ready to make sure we get the window.verse.id here, otherwise could start before that is set in verses/show

$(function() {
  if (typeof window.verse !== 'undefined') {
    consumer.subscriptions.create({
      channel: "VerseChannel",
      verse_id: window.verse.id
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log(`Connected to verse channel ${window.verse.id}!`);
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.content.action === 'deploy') {
          var el = window.verse.elements.find(x => x.name === data.content.element_name);
    
          game.scene.scenes[0].renderAtom(el, parseInt(data.content.x), parseInt(data.content.y), data.content.owner);
        
          // echo out the deploy event in terminal log on the right for all users.
          $('.terminal-output').append(`
            <span class="blue">${el.name}</span> <span class="green">deployed</span><br/>
          `);
        } else if (data.content.action === 'destroy') {
          var atomTile = game.scene.scenes[0]["group2"].children.entries.find(obj => {
            return (obj.isoX === parseInt(data.content.x) && obj.isoY === parseInt(data.content.y))
          });
          
          if (atomTile) {
            atomTile.destroy();

            $('.terminal-output').append(`
              <span class="blue">${data.content.element_name}</span> <span class="red">destroyed</span><br/>
            `);
          }        
        }
        
        // next need to update multiverse object for all.
        game.scene.scenes[0].multiverseLog = data.content.multiverseLog;
    
        $(".terminal").scrollTop(1E10);

        $('.gravity').html(`${Math.round(parseFloat(data.content.total_eth) * 100) / 100}`);
      }
    });
  }
});

// old structure.
// consumer.subscriptions.create("VerseChannel", {
//   connected() {
//     // Called when the subscription is ready for use on the server
//     console.log("Connected to verse channel!");
//   },

//   disconnected() {
//     // Called when the subscription has been terminated by the server
//   },

//   received(data) {
//     // Called when there's incoming data on the websocket for this channel
//     var el = elements[data.content.element_name];

//     game.scene.scenes[0].renderAtom(el, parseInt(data.content.x), parseInt(data.content.y), data.content.owner);
    
//     // next need to update multiverse object for all.
//     game.scene.scenes[0].multiverseLog = data.content.multiverseLog;

//     // echo out the deploy event in terminal log on the right for all users.
//     $('.terminal-output').append(`
//       <span class="blue">${el.name}</span> <span class="green">deployed</span> -><br/>
//       <div style="margin-left:20px">
//         payload: ${(el.payload).toString()} ETH<br/>
//         bonds: ${el.bonds}
//       </div>
//     `);

//     $(".terminal").scrollTop(1E10);
//   }
// });
