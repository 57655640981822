// _io files are all the events related to the dapp initiated by the frontend. Mint, draw, etc..
import { Jpc } from './jpc';


// these vars get set when user connects account.
let provider;
let signer;
let signerAddress;

const price = "0.04";

async function mintNFTs(quantity) {
  try {
    let quant = parseInt(quantity);
    let totalPrice = ethers.utils.parseEther(price).mul(quant);

    const contract = new ethers.Contract(window.contractAddress, window.contractAbi, signer);
    // const gas = await contract.estimateGas.mint(signerAddress, 1, { value: ethers.utils.parseEther("0.03") });
    // console.log(gas.toString());
    // console.log(ethers.utils.formatEther((await provider.getGasPrice()).toString()));
    const transaction = await contract.mint(signerAddress, quant, { value: totalPrice });
    let receipt = await transaction.wait();
    console.log(receipt);
    
    // if they mint more than 1, need to loop through to get all the ids from the logs.
    //console.log(receipt.logs[1]);
    // returns the id in hex form.
    $('.minting_nft').html(`<div style='margin-bottom:20px'><p>Minting complete! Click below to see your NFT details. It will take some time for the token data to appear on OpenSea.</p></div>`);

    for (let i = 0; i <= quant-1; i++) {
      // third topic will be the tokenId.
      // to convert hex to int in js, add base 16 to the radix. 
      let tokenId = parseInt(receipt.logs[i].topics[3], 16);
      let tokenHash = await contract.tokenIdToHash(tokenId);

      if (tokenId && tokenHash && parseInt(tokenHash.slice(0, 16), 16) !== 0) {
        window.sharedFunctions.logMintDetails({ projectId: 1, tokenId: tokenId, tokenHash: tokenHash, ethAddress: signerAddress });

        $('.minting_nft').append(`
          <p><strong>Token ID</strong>: ${tokenId}</p>
          <p><strong>JPC</strong>: <a href="/projects/jpcs/tokens/${tokenId}?hash=${tokenHash}" target="_blank" class="view_artwork_url">View JPC & Attributes</a></p>
        `);
      }
    }
  } catch(error) {
    alert(error);
    location.reload();
  }
}

function generateJpc(args) {  
  let jpc = new Jpc(args);
  window.jpc = jpc;

  $('.jpc_attributes').html(`
    <h2>M${jpc.model.number} ${jpc.composite.name}</h2>
    <h3><span class="red_text">${jpc.ptu} <span class="ptu_h2_size">PTU</span></span></h3>
  `);

  let p5Congfig = function(p) {
    p.setup = () => {
      //p.pixelDensity(2);
      p.createCanvas(jpc.canvasWidth, jpc.canvasHeight);
      p.background(jpc.composite.background);
      p.fill(0);
      p.strokeWeight(2);
      p.stroke(jpc.composite.stroke);
    };

    p.draw = () => {
      p.translate(
        (p.width - (jpc.xdim + 2) * jpc.scale) / 2, // + 2 centers it.
        (p.height - (jpc.ydim + 2) * jpc.scale) / 2
      );

      jpc.blocks.forEach(block => {
        p.fill(block.col ? block.col : '#fff');
        p.rect(
          block.x1 * jpc.scale,
          block.y1 * jpc.scale,
          block.w * jpc.scale,
          block.h * jpc.scale
        );
      });

      // only works for admins (server side check)
      if (args.saveMetadata) {
        console.log('saving metadata!');
        // can only get this base64 data if run the function here at end of draw. Otherwise the p5 runs after window loaded and canvas element is blank.
        saveMetadata({ 
          tokenHash: args.tokenHash, 
          urlHash: args.urlHash,
          tokenId: args.tokenId, 
          projectId: args.projectId, 
          features: jpc.getAttributes(), 
          imageData: p.canvas.toDataURL('image/jpeg', 1.0) // 1.0 is full quality.
        });
      }
      
      p.noLoop();
    };

    p.keyPressed = () => {
      if (p.keyCode === 80) p.saveCanvas(`jpc-${args.tokenId}`, 'jpg');
    };
  }

  new p5(p5Congfig);
}

function randomHash() {
  let x = "0123456789abcdef", hash = '0x'
  for (let i = 64; i > 0; --i) {
    hash += x[Math.floor(Math.random()*x.length)]
  }
  return hash
}

async function requestAccount() {
  if (typeof window.ethereum !== 'undefined') {
    await window.ethereum.request({ method: 'eth_requestAccounts' });

    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    signerAddress = (await signer.getAddress()).toString();

    //ethereum.on('accountsChanged', handleAccountsChanged);
    window.ethereum.on('accountsChanged', function (accounts) {
      console.log(accounts);
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
      } else if (accounts[0] !== signerAddress) {
        signer = provider.getSigner();
        signerAddress = accounts[0];

        $('.address_connected').html(signerAddress);
      }
    });

    return { provider: provider, signer: signer, signerAddress: signerAddress };
  }
}

async function connectWallet() {
  let response = await requestAccount();
  //setProviderSignerVars(response);
  $('.connect_wallet_wrapper').html(`
    Address Connected: <span class="address_connected">${response.signerAddress}</span>
    <div class="pre_submit_mint_content">
      <div class="nft_number_input_area">
        <p>Enter the quantity you'd like to mint (maximum of 20 per address)</p>
        <input type="number" min="1" max="20" value="1" class="mint_quantity" />
      </div>
      <div class="mint_button_area">
        <p><a href="#" class="mint_nft_submit">Mint</a></p>
      </div>
    </div>
    <div class="minting_nft">
      <div class="loading"></div>
      <p>Please wait...</p>
    </div>
  `);
}

// only works for admins. Server side check.
async function saveMetadata(details) {
  // browser's native fetch and blob conversions.
  const base64Response = await fetch(details.imageData); 
  const blob = await base64Response.blob();

  var formData = new FormData();

  formData.append('image_blob', blob);
  formData.append('token_hash', details.tokenHash);
  formData.append('url_hash', details.urlHash);
  formData.append('token_id', details.tokenId);
  formData.append('project_id', details.projectId);
  formData.append('features', JSON.stringify(details.features));

  $.ajax({
    url: "/tokens/admin_save_token_metadata", 
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: function(json) {
      console.log(json);
    },
    error: function(json) {
      console.log(json);
    }
  });
}

window.generateJpc = generateJpc;
window.randomHash = randomHash;

$(function() {
  $('.connect_wallet_jpc').on('click', function(e) {
    e.preventDefault();
    connectWallet();
  });

  $(document).on('keyup', '.mint_quantity', function(e) {
    if (parseInt($(this).val()) > 20) {
      alert('Maximum of 20 per address.');
      $(this).val(20);
    } else if (parseInt($(this).val()) <= 0) {
      $(this).val(1);
    }
  });

  $(document).on('click', '.mint_nft_submit', function(e) {
    e.preventDefault();
    let mintQuantity = parseInt($('.mint_quantity').val());
    $('.pre_submit_mint_content').hide();
    $('.minting_nft').show();
    mintNFTs(mintQuantity);
  });

  $('.draw_jpc_btn').on('click', function(e) {
    e.preventDefault();
    let hash;
    let hParam = $(this).data('hash');
    if (hParam.length) {
      hash = hParam;
    } else {
      hash = randomHash();
    }

    console.log(hash);
    generateJpc({ 
      tokenHash: hash,
      modelNumber: $(this).data('model'),
      compositeBoost: $(this).data('composite-boost'),
      canvasWidth: 1000,
      canvasHeight: 1000,
    });
  });
});