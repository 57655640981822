
class SharedFunctions {
  constructor() {}

  logMintDetails(args) {
    $.ajax({
      url: '/mint_logs/log_mint_details',
      type: 'POST',
      data: {
        project_id: args.projectId,
        token_id: args.tokenId, // @token doesn't exist yet in db, so need to use the token_id created in mint event.
        token_hash: args.tokenHash,
        eth_address: args.ethAddress,
      },
      dataType: 'JSON',
      success: (json) => {
        console.log(json);
      },
      error: (json) => {
        console.log(json);
      }
    });
  }

}

window.sharedFunctions = new SharedFunctions();