// For hot reloading changes to be fast, need to start up webpack-dev-server in another terminal simulataneosly: ./bin/webpack-dev-server
// import Phaser from 'phaser';
import Phaser, { Game, Scene } from 'phaser';
import IsoPlugin from 'phaser3-plugin-isometric';
import BigNumber from 'bignumber.js';

$(function() {
  if (typeof window.verse !== 'undefined') {
    window.tileHeight = 67;
    window.tileWidth = 61;
    
    // starting on first element.
    window.activeElement = window.verse.elements[0];
    
    $("body").on('click', '.elements a', function(e) {
      let el = window.verse.elements.find(x => x.name === $(this).data('name'));

      if (el) {
        window.activeElement = el;
        $.modal.close();
        $('.terminal-output').append(`
          <span class="blue">${window.activeElement.name}</span> selected<br/>
        `);
    
        $(".terminal").scrollTop(1E10);
      }
      
    });

    window.plato = $('.terminal-block').terminal({
      // atom: function(atomicSymbol) {
      //   window.activeElement = window.elements[atomicSymbol];

      //   this.echo(`
      //     <span class="blue">${window.activeElement.name}</span> selected`
      //   , { raw: true });
      // },
      // deploy: function(atomicSymbol, x, y, system) {

      //   this.echo(`
      //     <span class="blue">${elements[atomicSymbol].name}</span> <span class="green">deployed</span> -><br/>
      //     <div style="margin-left:20px">
      //       payload: ${(window.baseValue * elements[atomicSymbol].weight).toFixed(2)} ETH<br/>
      //       bonds: ${elements[atomicSymbol].bonds}
      //     </div>`
      //   , { raw: true });
      // },
      // info: function(system, x, y, z, neighbors) {
      //   var index = `${x}_${y}`;
      //   this.echo(`
      //     <span class="yellow">Atom:</span><br/><div style="margin-left:20px">atom: ${window.multiverseLog[index]['element']}<br/>Empty Bonds: ${window.multiverseLog[index]['ev']}<br/> payload: 100 Gwei<br/></div>`
      //   , { raw: true });
      // }
    }, {
      height: $(window).height() - $('.header_box').height() - 80,
      prompt: '$ ',
      greetings: false,
      scrollBottomOffset: 250
    });

    class Multiverse extends Scene {
      constructor() {
        const sceneConfig = {
          key: 'Multiverse',
          mapAdd: { isoPlugin: 'iso' }
        };
    
        super(sceneConfig);
      }

      preload() {
        this.load.image('tile', 'https://d29mtkonxnc5fw.cloudfront.net/site_assets/hex-25-flat-darker.png');

        this.load.scenePlugin({
          key: 'IsoPlugin',
          url: IsoPlugin,
          sceneKey: 'iso'
        });
      }

      create() {
        this.isoGroup = this.add.group();

        // could possibly add each users atoms to different group, so then they could click a button and we could apply a color or z-change to all of their positions so they could see them. Not sure if this is best/most efficient way to do this. Or if we could sort just by owner property on tile. problem is needing to declare this group variable in the create?
        // game.scene.scenes[0]["group2"].children.each(function(c) { c.setTint("0xFFFFFF") })
        this.group2 = this.add.group();

        this.iso.projector.origin.setTo(0.5, -0.68);
        this.iso.projector.projectionAngle = .54;

        this.cameras.main.setZoom(0.48);

        const cursors = this.input.keyboard.createCursorKeys();

        const controlConfig = {
            camera: this.cameras.main,
            left: cursors.left,
            right: cursors.right,
            up: cursors.up,
            down: cursors.down,
            zoomIn: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
            zoomOut: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E),
            acceleration: .02,
            drag: 0.0005,
            maxSpeed: 1.0
        };

        this.controls = new Phaser.Cameras.Controls.SmoothedKeyControl(controlConfig);

        this.multiverseLog = window.multiverseLog;

        this.spawnTiles();
      }

      // needed for the zoom and keyboard pan functionality.
      update (time, delta) {
        this.controls.update(delta);
      }

      spawnTiles() {
        var tile;
        let _this = this;
        let gravity = 0;
        let systemAtoms = 0;
        let systemEther = new BigNumber(0);
        const boardSize = parseInt(window.verse.boardSize);

        for (var xx = 0; xx < boardSize; xx += window.tileWidth) {
          for (var yy = 0; yy < boardSize; yy += window.tileWidth) {
            tile = this.add.isoSprite(xx, yy, 0, 'tile', this.isoGroup);
            tile.setTint(0x20222a);

            tile.setInteractive();

            tile.on('pointerover', function() {
              this.setTint(0x111);
              // this.isoZ += 2;
            });

            tile.on('pointerout', function() {
              //this.clearTint();
              this.setTint(0x20222a);
              // this.isoZ -= 2;
            });

            if (this.multiverseLog[`${xx}_${yy}`]) {
              let el = window.verse.elements.find(x => x.name === this.multiverseLog[`${xx}_${yy}`].name);
              if (el) {
                this.renderAtom(el, xx, yy, this.multiverseLog[`${xx}_${yy}`].owner);
              }
            }

            // if destroy atom on top of tile, still need this functionality, so not doing an if/else above.
            tile.on('pointerdown', function() {
              if (window.user.action === 'deploy') {
                if (_this.isValidConnectivity(window.activeElement.max_bonds, this.isoPosition.x, this.isoPosition.y, this.isoPosition.z)) {

                  _this.deployAtom(window.activeElement, this.isoPosition.x, this.isoPosition.y, window.user.address);
                } else {
                  this.setTint(0xff0000);
                }
              } else if (window.user.action === 'destroy') {
                $('.terminal-output').append(`
                  <span class="red">Nothing to Destroy</span><br/>
                `);

                $(".terminal").scrollTop(1E10);
              }
            }); 
          }
        }
      }

      renderAtom(element, x, y, owner) {
        var atom;
        var index = `${x}_${y}`;
        let _this = this;

        atom = this.add.isoSprite(x, y, 1, 'tile', this.group2);
        // should figure out what info to store on the tile itself, vs. fetching via the matrix.
        atom.name = element.name; // can use name for putting the element name on the tile.
        //atom.layer = 10;
        atom.owner = owner;

        atom.color = element.color;

        atom.payload = element.payload.toString();
        // could possibly show user's tiles as some highlight when they click a button by iterating through the tiles and finding ones that match this owner.
        // game.scene.scenes[0]["group2"].children.each(function(c) { if (c.owner == "0x3332") { c.setTint("0xFFFFFF") } }) 
        // to toggle back to original color after highlighting them white. Use below code.
        // game.scene.scenes[0]["group2"].children.each(function(c) { if (c.owner == "0x314") { c.setTint(c.color) } })
        // But could be more efficient to put different users atoms in their own group. 
        atom.setTint(element.color);
        atom.setInteractive();
        
        atom.on('pointerover', function() {
          this.isoZ += 4;
        });

        atom.on('pointerout', function() {
          this.isoZ -= 4;
        });

        atom.on('pointerdown', function() {
          if (window.user.action === 'destroy') {
            _this.destroyAtom(this.isoPosition.x, this.isoPosition.y, this.name);
          } else {
            // shows the info of the atom.
            $('.terminal-output').append(`
              <span class="yellow">${atom.name}:</span><br/>
              <div style="margin-left:20px">
                Bonds: ${_this.multiverseLog[index]['maxBonds'] - _this.multiverseLog[index]['emptyBonds']}/${_this.multiverseLog[index]['maxBonds']}<br/> 
                Payload: ${_this.formatToEth(_this.multiverseLog[index]['payload'])} ETH<br/>
                Owner: ${atom.owner}
              </div>
            `);

            $(".terminal").scrollTop(1E10);
          }

          // below is for making it 3d.
          //_this.deployAtom(x, y, z+window.activeElement.shapeHeight);

          // for destroying an atom. Need to add new terminal command for switching to destroy mode. Just have it toggle between info mode and destroy mode based on which global variable is set.
          // atom.on('pointerdown', function() {
          //   this.destroy();
          // });
        });
      }

      formatToEth(wei_amount) {
        let v = new BigNumber(wei_amount);
        return v.shiftedBy(-18);
      }

      deployAtom(element, x, y, owner) {
        let _this = this;

        $.ajax({
          url: `/verses/${window.verse.id}/deploy_atom`,
          type: 'POST',
          data: {
            x: x,
            y: y,
            element_id: element.id,
            name: element.name,
            empty_bonds: (element.max_bonds - _this.numberOfNeighbors(x, y, 1)),
            max_bonds: element.max_bonds,
            payload: element.payload.toString(),
            owner: owner,
          },
          dataType: 'JSON',
          success: (json) => {
            if (json.success == "true") {
              $('.eth_available').html(json.walletFree);
              let netAmount = json.rewardAmount - json.feeAmount;
              let netAmountUSD = (Math.round((netAmount * 2200) * 100) / 100).toFixed(2);
              let netHtml = '';

              if (netAmount >= 0) {
                netHtml = `<span class="green">${netAmountUSD}</span>`;
              } else {
                netHtml = `<span class="red">${netAmountUSD}</span>`;
              }

              $('.terminal-output').append(`
                <span>Fee:</span> <span class="red">${json.feeAmount}</span> ETH<br/>
                <span>Reward:</span> <span class="green">${json.rewardAmount}</span> ETH<br/>
                <span>Net:</span> ${netHtml} USD<br/>
                <span>Gravity:</span> ${parseFloat(json.gravity_ratio).toFixed(2)}%<br/>
              `);

              $(".terminal").scrollTop(1E10);    

              // updating this stuff with the actioncable broadcast now.
              // this.multiverseLog = json.multiverseLog;
              // this.renderAtom(element, x, y, owner);

              // plato.echo(`
              //   <span class="blue">${element.name}</span> <span class="green">deployed</span> -><br/>
              //   <div style="margin-left:20px">
              //     payload: ${(element.payload).toString()} ETH<br/>
              //     bonds: ${element.bonds}
              //   </div>`
              // , { raw: true });
            } else {
              $('.terminal-output').append(`
                <span>Unable to deploy</span><br/>
              `);

              $(".terminal").scrollTop(1E10);
            }
          },
          error: (json) => {
            console.log('error with deploy');
          },
        });
      }

      destroyAtom(x, y, name) {
        let _this = this;

        $.ajax({
          url: `/verses/${window.verse.id}/destroy_atom`,
          type: 'POST',
          data: {
            x: x,
            y: y,
            name: name,
          },
          dataType: 'JSON',
          success: (json) => {
            if (json.success === true) {
              $('.eth_available').html(json.walletFree);

              $('.terminal-output').append(`
                <span class="red">Fee:</span> ${json.feeAmount} ETH<br/>
                <span class="green">Reward:</span> ${json.rewardAmount} ETH<br/>
                <span>Gravity:</span> ${parseFloat(json.gravity_ratio).toFixed(2)}%<br/>
              `);

              $(".terminal").scrollTop(1E10);    
            } else {
              $('.terminal-output').append(`
                <span>Unable to destroy</span><br/>
              `);

              $(".terminal").scrollTop(1E10);
            }
          },
          error: (json) => {
            console.log('error with destroy');
          },
        });
      }

      // deprecated. Getting the new object from the server now.
      updateMultiverse(element, x, y, z, ethAddress) {
        var _this = this;
        var i = `${x}_${y}`;
        // If we made the key of the object the x_y coordinates, can check if one exists in this location and if valence available, otherwise need to block the drop if no valence left.
        // { 'x_y': { 'element': 'H', 'ev': 1 (empty valence), 'v': 1 (valence), 'owner': '0x3434....' }}
        
        this.multiverseLog[i] = { 'element': element.name, 'emptyBonds': element.max_bonds - _this.numberOfNeighbors(x, y, z), 'maxBonds': element.max_bonds, 'payload': element.payload.toString(), 'owner': ethAddress };

        // when dropping, need to modify every connected atom to update their 'ev' (empty valence)
        this.getNeighborPositions(x, y, z).forEach(position => {
          var ii = `${position[0]}_${position[1]}`;

          if (this.multiverseLog[ii]) {
            this.multiverseLog[ii]['emptyBonds'] -= 1;
          }
        });

        console.log(this.multiverseLog);
      }

      getNeighborPositions(x, y, z) {
        var neighbors = [
          [x - window.tileWidth, y - window.tileWidth, z], //top
          [x + window.tileWidth, y + window.tileWidth, z], //bottom
          [x - window.tileWidth, y, z], //left top diag
          [x, y + window.tileWidth, z], //left bottom diag
          [x, y - window.tileWidth, z], //right top diag
          [x + window.tileWidth, y, z], //rigth bottom diag
        ];
        
        return neighbors;
      }

      numberOfNeighbors(x, y, z) {
        var numberOfNeighbors = 0;

        this.getNeighborPositions(x, y, z).forEach(position => {
          var i = `${position[0]}_${position[1]}`;

          if (this.multiverseLog[i]) {
            numberOfNeighbors++;
          }
        });

        return numberOfNeighbors;
      }

      isValidConnectivity(maxBonds, x, y, z) {
        var validSpot = true;
        var numberOfNeighbors = 0;
        var _this = this;

        this.getNeighborPositions(x, y, z).forEach(position => {
          var i = `${position[0]}_${position[1]}`;

          if (_this.multiverseLog[i]) {
            // element exists at this position. Now check to make sure ev (empty valence) available for a bond. Also iterate numberOfNeighbors, because also need to be sure that current valence isn't less than number of neighbors.
            numberOfNeighbors++;

            if (_this.multiverseLog[i]['emptyBonds'] == 0) {
              // not possible to drop this element here because this neighbor is out of valence.
              validSpot = false;
            }
          }
        });

        if (maxBonds < numberOfNeighbors) {
          validSpot = false;
        }

        return validSpot;
      }
    }

    // $('#wallet').on('click', function(event) {
    //   event.preventDefault();
    //   this.blur(); // Manually remove focus from clicked link.
    //   $.get(this.href, function(html) {
    //     $('.wallet_modal_area').html(html);
    //     $('.modal').modal();
    //     //$(html).appendTo('body').modal();
    //   });
    // });

    var config = {
      type: Phaser.WEBGL,
      parent: 'phaser-example',
      width: $(window).width(),
      height: $(window).height(),
      backgroundColor: '#262833',
      pixelArt: false,
      resolution: window.devicePixelRatio,
      scene: Multiverse
    }

    var game = new Phaser.Game(config);
    window.game = game;
    

    var highlighted = false;

    $('.show_my_atoms').on('click', function(event) {
      event.preventDefault();
      if (!highlighted) {
        game.scene.scenes[0]["group2"].children.each(function(c) { if (c.owner == window.user.address) { c.setTint("0xFFFFFF") } });
        $('.highlight_plus').html('-');
        highlighted = true;
      } else {
        game.scene.scenes[0]["group2"].children.each(function(c) { if (c.owner == window.user.address) { c.setTint(c.color) } });
        $('.highlight_plus').html('+');
        highlighted = false;
      }
    });

    $("body").on('click', ".action_select", function(e) {
      window.user.action = $(this).val();
      $(this).prop("checked", true);
    });

    $('body').on('click', '#deploy_destroy_toggle', function(e) {
      $('.toggle_text span').removeClass('active_deploy_destroy_text');

      if ($(this).is(':checked')) {
        window.user.action = 'destroy';
        $('.destroy_text').addClass('active_deploy_destroy_text');
      } else {
        window.user.action = 'deploy';
        $('.deploy_text').addClass('active_deploy_destroy_text');
      }
    });
  }
});

