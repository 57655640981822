
// license: "MIT"
export class Jpc {
  constructor(args) {
    // these values come from smart contract
    this.tokenHash = args.tokenHash;
    this.tokenId = args.tokenId;
    this.modelNumber = args.modelNumber;
    this.compositeBoost = args.compositeBoost;
    this.ptu = args.ptu;
    // end smart contract values
    this.seed = parseInt(this.tokenHash.slice(0, 16), 16);
    this.pairs = [];
    for (let i = 0; i < 64; i+=2) {
      this.pairs.push(this.tokenHash.slice(i+2, i+4));
    }
    this.rns = this.pairs.map(x => {return parseInt(x, 16) % 10});
    this.width = 130;
    this.height = 130;
    this.solidness = this.rns[0] >= 5 ? 0.06 : 0.07;
    this.initiateChance = this.rns[1] >= 3 ? 0.88 : 0.87;
    this.extensionChance = this.rns[2] > 4 ? 0.85 : 0.84;
    this.verticalChance = this.rns[3] < 6 ? 0.6 : 0.8;
    this.roundness = 0.01;
    this.xdim = Math.round(this.width * 2, 0);
    this.ydim = Math.round(this.height * 2, 0);
    this.radiusx = this.width;
    this.radiusy = this.height;
    this.blocks;
    this.canvasWidth = args.canvasWidth || window.innerWidth;
    this.canvasHeight = args.canvasHeight || window.innerHeight;
    this.dim = Math.round(Math.min(this.canvasWidth, this.canvasHeight) * 2); // 2 modifier to make it work out to good scale with the fuzziness
    this.scale = Math.round(this.dim / this.height);

    // These arrays are stored in smart contract
    //this.shuffledModels = [4, 4, 1, 2, 1, 5, 3, 1, 2, 2, 1, 4, 3, 5, 6, 1, 2, 3, 1, 3, 2];

    //this.shuffledCompositeBoosts = [40, 10, 40, 20, 50, 30, 20, 50, 10, 30, 70, 40, 30, 80, 10, 20, 40, 20, 60, 10, 50, 30, 10, 60, 50, 70, 30, 60, 80, 40, 10, 70, 20, 10, 60, 90, 20, 40, 20, 50, 10, 20, 30, 10, 30];

    this.composites = [
      {
        name: 'Chromium', // blue, orange, yellow
        colors: ['#51c8ff', '#FE9C00', '#FFFF00'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 10
      },
      {
        name: 'Halfnium', // orange, yellow
        colors: ['#51c8ff', '#FFFF00'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 20
      },
      {
        name: 'Tantalum', // yellow, purple
        colors: ['#FFFF00', '#bebeef'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 30
      },
      {
        name: 'Lithium', // red, blue
        colors: ['#FF7844', '#51c8ff'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 40
      },
      {
        name: 'Gallium', // purples
        colors: ['#C4BBF0', '#927FBF'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 50
      },
      {
        name: 'Nickel', // blue, orange
        colors: ['#51c8ff', '#FE9C00'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 60
      },
      {
        name: 'Cadmium', // red, purple
        colors: ['#FF7844', '#bebeef'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 70
      },
      {
        name: 'Indium', // blues
        colors: ['#90B8F8', '#5F85DB'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 80
      },
      {
        name: 'Uranium',
        colors: ['#00ADB5', '#0ef8f8'],
        background: '#0C141F',
        stroke: '#0C141F',
        boost: 90
      }, 
    ];

    this.models = [
      { value: 0.79, number: 6 }, 
      { value: 0.80, number: 5 },
      { value: 0.81, number: 4 },
      { value: 0.82, number: 3 },
      { value: 0.83, number: 2 },
      { value: 0.84, number: 1 },
    ];
    
    this.composite = this.composites.find(x => x.boost == this.compositeBoost);
    this.model = this.models.find(x => x.number == this.modelNumber);

    this.blocks = this.generateGrid();
    this.components = this.blocks.length;
  }

  makeModelsArray() {
    let distributionMap = [
      { value: 6, times: 1 }, 
      { value: 5, times: 2 },
      { value: 4, times: 3 },
      { value: 3, times: 4 },
      { value: 2, times: 5 },
      { value: 1, times: 6 },
    ];

    let modelsArray = this.createArrayFromDistributionMap(distributionMap);

    let shuffledModelsArray = this.shuffle(modelsArray);
    return shuffledModelsArray;
  }

  makeCompositesArray() {
    let distributionMap = [
      { value: 1, times: 9 }, 
      { value: 2, times: 8 },
      { value: 3, times: 7 },
      { value: 4, times: 6 },
      { value: 5, times: 5 },
      { value: 6, times: 4 },
      { value: 7, times: 3 },
      { value: 8, times: 2 },
      { value: 9, times: 1 },
    ];

    let a = this.createArrayFromDistributionMap(distributionMap);

    let shuffledArray = this.shuffle(a);
    return shuffledArray;
  }

  createArrayFromDistributionMap(distributionMap) {
    let a = [];

    distributionMap.forEach(item => {
      for (let i = 1; i <= item.times; i++) {
        a.push(item.value);
      }
    });
    return a;
  }

  // fisher-yates shuffle
  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  // for the api response
  getAttributes() {
    let attributes = [
      {
        "trait_type": "Model", 
        "value": `${this.model.number}`,
      },
      {
        "trait_type": "Composite", 
        "value": `${this.composite.name}`,
      }, 
      {
        "trait_type": "Composite Boost", 
        "value": this.composite.boost,
      },
      {
        "trait_type": "Components", 
        "value": this.components
      },
      {
        "trait_type": "PTU", 
        "value": this.ptu
      },
    ];
  
    return attributes;
  }

  generateGrid() {
    let grid = new Array(this.ydim + 1);
    
    for (var i = 0; i < grid.length; i++) {
      grid[i] = new Array(this.xdim + 1);
      for (var j = 0; j < grid[i].length; j++) {
        if (i == 0 || j == 0) {
          grid[i][j] = { h: false, v: false, in: false, col: null };
        } else if (j > grid[i].length / 2) {
          grid[i][j] = this.deepCopy(grid[i][grid[i].length - j]);
          grid[i][j].v = grid[i][grid[i].length - j + 1].v;
        } else if (i > grid.length / 2) {
          grid[i][j] = this.deepCopy(grid[grid.length - i][j]);
          grid[i][j].h = grid[grid.length - i + 1][j].h;
        } else {
          grid[i][j] = this.nextComponent(j, i, grid[i][j - 1], grid[i - 1][j]);
        }
      }
    }

    let rects = this.convertGridToRectangles(grid);
    return rects;
  }

  nextComponent(x, y, left, top) {
    if (!left.in && !top.in) {
      return this.cSet1(x, y);
    }

    if (left.in && !top.in) {
      if (left.h) return this.cSet3(x, y, left);
      return this.cSet2(x, y);
    }

    if (!left.in && top.in) {
      if (top.v) return this.cSet5(x, y, top);
      return this.cSet4(x, y);
    }

    if (left.in && top.in) {
      if (!left.h && !top.v) return this.cSet6(left);
      if (left.h && !top.v) return this.cSet7(x, y, left);
      if (!left.h && top.v) return this.cSet8(x, y, top);
      return this.cSet9(left, top);
    }
  }

  cSet1(x, y) {
    if (this.beginNewBlock(x, y)) return this.newSet();
    return { v: false, h: false, in: false, col: null };
  }

  cSet2(x, y) {
    if (this.beginNewBlock(x, y)) return this.newSet();
    return { v: true, h: false, in: false, col: null };
  }

  cSet3(x, y, left) {
    if (this.extendBlock(x, y)) return { v: false, h: true, in: true, col: left.col };
    return this.cSet2(x, y);
  }

  cSet4(x, y) {
    if (this.beginNewBlock(x, y)) return this.newSet();
    return { v: false, h: true, in: false, col: null };
  }

  cSet5(x, y, top) {
    if (this.extendBlock(x, y)) return { v: true, h: false, in: true, col: top.col };
    return this.cSet4(x, y);
  }

  cSet6(left) {
    return { v: false, h: false, in: true, col: left.col };
  }

  cSet7(x, y, left) {
    if (this.extendBlock(x, y)) return { v: false, h: true, in: true, col: left.col };
    if (this.initiateBlock(x, y)) return this.newSet();
    return { v: true, h: true, in: false, col: null };
  }

  cSet8(x, y, top) {
    if (this.extendBlock(x, y)) return { v: true, h: false, in: true, col: top.col };
    if (this.initiateBlock(x, y)) return this.newSet();
    return { v: true, h: true, in: false, col: null };
  }

  cSet9(left, top) {
    if (this.verticalDir()) return { v: true, h: false, in: true, col: top.col };
    return { v: false, h: true, in: true, col: left.col };
  }

  newSet() {
    return { v: true, h: true, in: true, col: this.getRandomArrayElement(this.composite.colors) };
  }

  beginNewBlock(x, y) {
    if (!this.activePoint(x, y, -1 * (1 - this.roundness))) return false;
    return this.rnd() <= this.solidness;
  }

  initiateBlock(x, y) {
    if (!this.activePoint(x, y, 0)) return false;
    return this.rnd() <= this.initiateChance;
  }

  extendBlock(x, y) {
    if (!this.activePoint(x, y, 1 - this.roundness)) return false;
    return this.rnd() <= this.extensionChance;
  }

  verticalDir() {
    return this.rnd() <= this.verticalChance;
  }

  activePoint(x, y, fuzzy) {
    let fuzziness = 1 + this.model.value * fuzzy;

    let xa = Math.pow(x - this.xdim / 2, 2) / Math.pow(this.radiusx * fuzziness, 2);
    let ya = Math.pow(y - this.ydim / 2, 2) / Math.pow(this.radiusy * fuzziness, 2);
    return xa + ya < 1;
  }

  deepCopy(obj) {
    let nobj = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        nobj[key] = obj[key];
      }
    }
    return nobj;
  }

  convertGridToRectangles(grid) {
    let nwCorners = this.getCorners(grid);
    this.extendCornersToRectangles(nwCorners, grid);
    return nwCorners;
  }

  getCorners(grid) {
    let nwCorners = [];
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid[i].length; j++) {
        let cell = grid[i][j];
        if (cell.h && cell.v && cell.in) nwCorners.push({ x1: j, y1: i, col: cell.col });
      }
    }
    return nwCorners;
  }

  extendCornersToRectangles(corners, grid) {
    corners.map(c => {
      let accx = 1;
      while (c.x1 + accx < grid[c.y1].length && !grid[c.y1][c.x1 + accx].v) {
        accx++;
      }
      let accy = 1;
      while (c.y1 + accy < grid.length && !grid[c.y1 + accy][c.x1].h) {
        accy++;
      }
      c.w = accx;
      c.h = accy;
      return c;
    });
  }

  rnd() {
    // calls to rnd will return the same sequence of random numbers in the same order. So the number and order of calls is important. Adding even a console.log of rnd changes outcome.
    this.seed ^= this.seed << 13;
    this.seed ^= this.seed >> 17;
    this.seed ^= this.seed << 5;
    let v = ((this.seed < 0 ? ~this.seed + 1 : this.seed) % 1000) / 1000;
    return v;
    
  }

  getRandomArrayElement(array) {
    return array[Math.floor(this.rnd() * array.length)];
  }
}

